// extracted by mini-css-extract-plugin
export var accountStatus__description = "SandboxComponents-module--accountStatus__description--c1feb";
export var accountStatus__img = "SandboxComponents-module--accountStatus__img--5bde4";
export var accountStatus__title = "SandboxComponents-module--accountStatus__title--9dfb4";
export var accountStatus__wrapper = "SandboxComponents-module--accountStatus__wrapper--b2479";
export var heroSection__content = "SandboxComponents-module--heroSection__content--9b222";
export var heroSection__description = "SandboxComponents-module--heroSection__description--0178d";
export var heroSection__logo = "SandboxComponents-module--heroSection__logo--518bb";
export var heroSection__title = "SandboxComponents-module--heroSection__title--c9017";
export var heroSection__wrapper = "SandboxComponents-module--heroSection__wrapper--39949";
export var loginForm__wrapper = "SandboxComponents-module--loginForm__wrapper--2bc63";
export var partnerSection__container = "SandboxComponents-module--partnerSection__container--14a0b";
export var partnerSection__img = "SandboxComponents-module--partnerSection__img--5d999";
export var partnerSection__loginQuestion = "SandboxComponents-module--partnerSection__loginQuestion--22a48";
export var partnerSection__title = "SandboxComponents-module--partnerSection__title--a1230";
export var section__wrapper = "SandboxComponents-module--section__wrapper--edc17";
export var signUpForm__description = "SandboxComponents-module--signUpForm__description--55659";
export var signUpForm__error = "SandboxComponents-module--signUpForm__error--3f891";
export var signUpForm__form = "SandboxComponents-module--signUpForm__form--af1fa";
export var signUpForm__input = "SandboxComponents-module--signUpForm__input--a4e29";
export var signUpForm__label = "SandboxComponents-module--signUpForm__label--25db1";
export var signUpForm__title = "SandboxComponents-module--signUpForm__title--359e6";
export var signUpForm__wrapper = "SandboxComponents-module--signUpForm__wrapper--81dad";