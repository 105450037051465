import React from "react";
import { motion } from "framer-motion";

//Styles
import * as styles from "../../styles/components/SandboxComponents.module.css";

const AccountStatusComponent = ({ image, title, description }) => {
  return (
    <motion.div className={styles.accountStatus__wrapper}>
      <motion.img src={image} className={styles.accountStatus__img} />
      <motion.h2 className={styles.accountStatus__title}>{title}</motion.h2>
      <motion.p className={styles.accountStatus__description}>
        {description}
      </motion.p>
    </motion.div>
  );
};

export default AccountStatusComponent;
